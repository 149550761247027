import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import cerai from '../../Attachments/Image/cerai.png';
import KDRT from '../../Attachments/Image/KDRT.png';
import perjanjianNikah from "../../Attachments/Image/PerjanjianNikah.jpg";
import ahliWaris from "../../Attachments/Image/ahliWaris.jpg";



export const ArticleGrid = () => {
    const classname = {
      container:
        "w-screen h-screen grid grid-cols-1 lg:grid-cols-2 px-16 lg:px-36 gap-6 lg:gap-4 py-36 place-items-center font-Roboto",
      highlightPost: "space-y-5 grid w-full group",
      left: "w-full h-full p-3 grid place-items-center",
      text: "text-gray-300",
      right: "w-full h-full pr-14 grid place-items-center",
      grid: "grid grid-cols-2 gap-3 w-full h-full ",
      boldTitle: "font-bold text-xl group-hover:underline",
      img: "h-60 w-full object-contain bg-black"
    };


    return (
      <div className="bg-dark-white text-white w-full h-full">
        <Navbar />
        <div className={classname.container}>
          <div className={classname.left}>
            <div className="text-center lg:text-start">
              <h1 className="font-bold text-3xl">Terbaru</h1>
              <p className="text-gray-300">Baca topik" terbaru</p>
            </div>
          </div>
          <div className={classname.right}>
            <div className={classname.grid}>
              <a href="https://medium.com/@TOBALAWFIRM/hak-hak-perempuan-dan-anak-pasca-perceraian-57621b55f6e4">
                <div className={classname.highlightPost}>
                  <img src={cerai} className={classname.img} />
                  <h1 className={classname.boldTitle}>
                    Hak Hak Perempuan dan Anak Pasca Perceraian
                  </h1>
                </div>
              </a>
              <a href="">
                <div className={classname.highlightPost}>
                  <img
                    src="https://dummyimage.com/600x400/000/fff.png&text=Segera+Meluncur!"
                    className={classname.img}
                  />
                  <h1 className={classname.boldTitle}>
                    Segera Tayang!
                  </h1>
                </div>
              </a>
              <a href="">
                <div className={classname.highlightPost}>
                  <img
                    src="https://dummyimage.com/600x400/000/fff.png&text=Segera+Meluncur!"
                    className={classname.img}
                  />
                  <h1 className={classname.boldTitle}>
                    Segera Tayang!
                  </h1>
                </div>
              </a>
              <a href="">
                <div className={classname.highlightPost}>
                  <img
                    src="https://dummyimage.com/600x400/000/fff.png&text=Segera+Meluncur!"
                    className={classname.img}
                  />
                  <h1 className={classname.boldTitle}>
                    Segera Tayang!
                  </h1>
                </div>
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );

};